import React from 'react'
import Overlay from './overlay'
import Rectangle from './rectangle'

export const overlays = {
  hero: (
    <>
      <Overlay visibility="desktop" position="right" width={650} height={336}>
        <Rectangle x={566} y={84} width={84} height={84} fill="cyan" />
        <Rectangle x={0} y={308} width={168} height={28} fill="yellow" />
      </Overlay>
      <Overlay visibility="desktop" position="bottom-right" width={534} height={200}>
        <Rectangle x={112} y={0} width={28} height={28} fill="white" />
        <Rectangle x={0} y={28} width={112} height={56} fill="navy" />
      </Overlay>
      <Overlay visibility="mobile" position="bottom-right" width={232.02} height={312}>
        <Rectangle x={203.02} y={0} width={29} height={19} fill="yellow" />
        <Rectangle x={0} y={196.37} width={18.7} height={18.7} fill="white" />
        <Rectangle x={18.7} y={215.07} width={37.4} height={37.4} fill="navy" />
      </Overlay>
    </>
  ),
}
